export enum AllowedCountriesForBusiness {
  'United States' = 'United States',
}

export enum AllowedCountriesForBuyer {
  Afghanistan = 'Afghanistan',
  Albania = 'Albania',
  Algeria = 'Algeria',
  Andorra = 'Andorra',
  Angola = 'Angola',
  'Antigua and Barbuda' = 'Antigua and Barbuda',
  Argentina = 'Argentina',
  Armenia = 'Armenia',
  Austria = 'Austria',
  Azerbaijan = 'Azerbaijan',
  Bahrain = 'Bahrain',
  Bangladesh = 'Bangladesh',
  Barbados = 'Barbados',
  Belarus = 'Belarus',
  Belgium = 'Belgium',
  Belize = 'Belize',
  Benin = 'Benin',
  Bhutan = 'Bhutan',
  Bolivia = 'Bolivia',
  'Bosnia and Herzegovina' = 'Bosnia and Herzegovina',
  Botswana = 'Botswana',
  Brazil = 'Brazil',
  Brunei = 'Brunei',
  Bulgaria = 'Bulgaria',
  'Burkina Faso' = 'Burkina Faso',
  Burundi = 'Burundi',
  'Cabo Verde' = 'Cabo Verde',
  Cambodia = 'Cambodia',
  Cameroon = 'Cameroon',
  Canada = 'Canada',
  'Central African Republic' = 'Central African Republic',
  Chad = 'Chad',
  'Channel Islands' = 'Channel Islands',
  Chile = 'Chile',
  China = 'China',
  Colombia = 'Colombia',
  Comoros = 'Comoros',
  Congo = 'Congo',
  'Costa Rica' = 'Costa Rica',
  'Côte d’Ivoire' = 'Côte d’Ivoire',
  Croatia = 'Croatia',
  Cuba = 'Cuba',
  Cyprus = 'Cyprus',
  'Czech Republic' = 'Czech Republic',
  Denmark = 'Denmark',
  Djibouti = 'Djibouti',
  Dominica = 'Dominica',
  'Dominican Republic' = 'Dominican Republic',
  'DR Congo' = 'DR Congo',
  Ecuador = 'Ecuador',
  Egypt = 'Egypt',
  'El Salvador' = 'El Salvador',
  'Equatorial Guinea' = 'Equatorial Guinea',
  Eritrea = 'Eritrea',
  Estonia = 'Estonia',
  Eswatini = 'Eswatini',
  Ethiopia = 'Ethiopia',
  'Faeroe Islands' = 'Faeroe Islands',
  Finland = 'Finland',
  France = 'France',
  'French Guiana' = 'French Guiana',
  Gabon = 'Gabon',
  Gambia = 'Gambia',
  Georgia = 'Georgia',
  Germany = 'Germany',
  Ghana = 'Ghana',
  Gibraltar = 'Gibraltar',
  Greece = 'Greece',
  Grenada = 'Grenada',
  Guatemala = 'Guatemala',
  Guinea = 'Guinea',
  'Guinea-Bissau' = 'Guinea-Bissau',
  Guyana = 'Guyana',
  Haiti = 'Haiti',
  'Holy See' = 'Holy See',
  Honduras = 'Honduras',
  'Hong Kong' = 'Hong Kong',
  Hungary = 'Hungary',
  Iceland = 'Iceland',
  India = 'India',
  Indonesia = 'Indonesia',
  Iran = 'Iran',
  Iraq = 'Iraq',
  Ireland = 'Ireland',
  'Isle of Man' = 'Isle of Man',
  Israel = 'Israel',
  Italy = 'Italy',
  Jamaica = 'Jamaica',
  Japan = 'Japan',
  Jordan = 'Jordan',
  Kazakhstan = 'Kazakhstan',
  Kenya = 'Kenya',
  Kuwait = 'Kuwait',
  Kyrgyzstan = 'Kyrgyzstan',
  Laos = 'Laos',
  Latvia = 'Latvia',
  Lebanon = 'Lebanon',
  Lesotho = 'Lesotho',
  Liberia = 'Liberia',
  Libya = 'Libya',
  Liechtenstein = 'Liechtenstein',
  Lithuania = 'Lithuania',
  Luxembourg = 'Luxembourg',
  Macao = 'Macao',
  Madagascar = 'Madagascar',
  Malawi = 'Malawi',
  Malaysia = 'Malaysia',
  Maldives = 'Maldives',
  Mali = 'Mali',
  Malta = 'Malta',
  Mauritania = 'Mauritania',
  Mauritius = 'Mauritius',
  Mayotte = 'Mayotte',
  Mexico = 'Mexico',
  Moldova = 'Moldova',
  Monaco = 'Monaco',
  Mongolia = 'Mongolia',
  Montenegro = 'Montenegro',
  Morocco = 'Morocco',
  Mozambique = 'Mozambique',
  Myanmar = 'Myanmar',
  Namibia = 'Namibia',
  Nepal = 'Nepal',
  Netherlands = 'Netherlands',
  Nicaragua = 'Nicaragua',
  Niger = 'Niger',
  Nigeria = 'Nigeria',
  'North Korea' = 'North Korea',
  'North Macedonia' = 'North Macedonia',
  Norway = 'Norway',
  Oman = 'Oman',
  Pakistan = 'Pakistan',
  Panama = 'Panama',
  Paraguay = 'Paraguay',
  Peru = 'Peru',
  Philippines = 'Philippines',
  Poland = 'Poland',
  Portugal = 'Portugal',
  Qatar = 'Qatar',
  Réunion = 'Réunion',
  Romania = 'Romania',
  Russia = 'Russia',
  Rwanda = 'Rwanda',
  'Saint Helena' = 'Saint Helena',
  'Saint Kitts and Nevis' = 'Saint Kitts and Nevis',
  'Saint Lucia' = 'Saint Lucia',
  'Saint Vincent and the Grenadines' = 'Saint Vincent and the Grenadines',
  'San Marino' = 'San Marino',
  'Sao Tome & Principe' = 'Sao Tome & Principe',
  'Saudi Arabia' = 'Saudi Arabia',
  Senegal = 'Senegal',
  Serbia = 'Serbia',
  Seychelles = 'Seychelles',
  'Sierra Leone' = 'Sierra Leone',
  Singapore = 'Singapore',
  Slovakia = 'Slovakia',
  Slovenia = 'Slovenia',
  Somalia = 'Somalia',
  'South Africa' = 'South Africa',
  'South Korea' = 'South Korea',
  'South Sudan' = 'South Sudan',
  Spain = 'Spain',
  'Sri Lanka' = 'Sri Lanka',
  'State of Palestine' = 'State of Palestine',
  Sudan = 'Sudan',
  Suriname = 'Suriname',
  Sweden = 'Sweden',
  Switzerland = 'Switzerland',
  Syria = 'Syria',
  Taiwan = 'Taiwan',
  Tajikistan = 'Tajikistan',
  Tanzania = 'Tanzania',
  Thailand = 'Thailand',
  'The Bahamas' = 'The Bahamas',
  'Timor-Leste' = 'Timor-Leste',
  Togo = 'Togo',
  'Trinidad and Tobago' = 'Trinidad and Tobago',
  Tunisia = 'Tunisia',
  Turkey = 'Turkey',
  Turkmenistan = 'Turkmenistan',
  Uganda = 'Uganda',
  Ukraine = 'Ukraine',
  'United Arab Emirates' = 'United Arab Emirates',
  'United Kingdom' = 'United Kingdom',
  'United States' = 'United States',
  Uruguay = 'Uruguay',
  Uzbekistan = 'Uzbekistan',
  Venezuela = 'Venezuela',
  Vietnam = 'Vietnam',
  'Western Sahara' = 'Western Sahara',
  Yemen = 'Yemen',
  Zambia = 'Zambia',
  Zimbabwe = 'Zimbabwe',
}

export enum AllowedUSAStates {
  Alabama = 'Alabama',
  Alaska = 'Alaska',
  Arizona = 'Arizona',
  Arkansas = 'Arkansas',
  California = 'California',
  Colorado = 'Colorado',
  Connecticut = 'Connecticut',
  Delaware = 'Delaware',
  Florida = 'Florida',
  Georgia = 'Georgia',
  Hawaii = 'Hawaii',
  Idaho = 'Idaho',
  Illinois = 'Illinois',
  Indiana = 'Indiana',
  Iowa = 'Iowa',
  Kansas = 'Kansas',
  Kentucky = 'Kentucky',
  Louisiana = 'Louisiana',
  Maine = 'Maine',
  Maryland = 'Maryland',
  Massachusetts = 'Massachusetts',
  Michigan = 'Michigan',
  Minnesota = 'Minnesota',
  Mississippi = 'Mississippi',
  Missouri = 'Missouri',
  Montana = 'Montana',
  Nebraska = 'Nebraska',
  Nevada = 'Nevada',
  'New Hampshire' = 'New Hampshire',
  'New Jersey' = 'New Jersey',
  'New Mexico' = 'New Mexico',
  'New York' = 'New York',
  'North Carolina' = 'North Carolina',
  'North Dakota' = 'North Dakota',
  Ohio = 'Ohio',
  Oklahoma = 'Oklahoma',
  Oregon = 'Oregon',
  Pennsylvania = 'Pennsylvania',
  'Rhode Island' = 'Rhode Island',
  'South Carolina' = 'South Carolina',
  'South Dakota' = 'South Dakota',
  Tennessee = 'Tennessee',
  Texas = 'Texas',
  Utah = 'Utah',
  Vermont = 'Vermont',
  Virginia = 'Virginia',
  Washington = 'Washington',
  'West Virginia' = 'West Virginia',
  Wisconsin = 'Wisconsin',
  Wyoming = 'Wyoming',
}

export enum AllowedCanadaProvinces {
  Alberta = 'Alberta',
  'British Columbia' = 'British Columbia',
  Manitoba = 'Manitoba',
  'New Brunswick' = 'New Brunswick',
  'Newfoundland and Labrador' = 'Newfoundland and Labrador',
  'Northwest Territories' = 'Northwest Territories',
  'Nova Scotia' = 'Nova Scotia',
  Nunavut = 'Nunavut',
  Ontario = 'Ontario',
  'Prince Edward Island' = 'Prince Edward Island',
  Quebec = 'Quebec',
  Saskatchewan = 'Saskatchewan',
  Yukon = 'Yukon',
}
